import {Company, LogicError} from '@legit.health/ui';
import UserContext, {IUserContext, useSafeContext} from './UserContext';
import {CurrentUserBase} from './types';

interface ILoggedUserContext extends IUserContext {
  user: CurrentUserBase;
  company: Company;
}

export function useCompany() {
  const {company} = useUserContext();
  if (!company) {
    throw new LogicError('Avoid calling useCompany without a company');
  }
  return company;
}

export function useSetCompany() {
  const {setCompany} = useUserContext();
  return setCompany;
}

export function useIsLoggedIn() {
  const {user} = useUserContext();
  return Boolean(user);
}

export function useCompanies() {
  const {companies} = useUserContext();
  return companies;
}

export function useIsPractitioner() {
  const {isPractitioner} = useUserContext();
  return isPractitioner;
}

export function useIsPatient() {
  return !useIsPractitioner();
}

export function useUserContext() {
  return useSafeContext(UserContext);
}

export default function useLoggedUserContext() {
  const value = useSafeContext(UserContext);
  if (value.user === null || value.company === null) {
    throw new Error('Invalid context');
  }

  return value as ILoggedUserContext;
}

export function useLogout() {
  const {logout} = useUserContext();
  return logout;
}
