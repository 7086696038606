import {createContext, ReactNode, useContext, useMemo, Context} from 'react';
import {Company, UserType} from '@legit.health/ui';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import ErrorView from '@/shared/components/ErrorBoundary/ErrorView';
import AppLoading from '@/shared/components/ui/AppLoading';
import AuthTokens from '@/shared/interfaces/AuthTokens';
import {CompanyTeaser} from '@/shared/interfaces/User';
import SelectCompany from './SelectCompany';
import {CurrentUserBase} from './types';
import useUser from './useUser';

const defaultValue = Symbol('User Context default value');
type DefaultValue = symbol;

export function useSafeContext<T>(TheContext: Context<T | DefaultValue>): T {
  const value = useContext(TheContext);
  if (value === defaultValue) {
    throw new Error('no value provided for context');
  }
  return value as T;
}

export interface IUserContext {
  fetchUser: () => void;
  user: CurrentUserBase | null;
  company: Company | null;
  setCompany: (company: Company | null) => void;
  login: (authTokens: AuthTokens) => Promise<boolean>;
  logout: () => void;
  isPractitioner: boolean;
  isCompanyActive: boolean;
  companies: CompanyTeaser[];
}

const UserContext = createContext<IUserContext | DefaultValue>(defaultValue);

export default UserContext;

export function UserContextProvider({children}: {children: ReactNode}) {
  const {t} = useTranslation(['shared/common']);
  const {
    user,
    fetchUser,
    fetchUserError,
    logout,
    login,
    company,
    setCompany,
    loading,
    companies,
    fetchCompany
  } = useUser();
  const isPractitioner = user?.type === UserType.Practitioner;
  const isCompanyActive = company?.isActive ?? false;

  const value = useMemo(
    () => ({
      login,
      logout,
      user,
      company,
      isPractitioner,
      isCompanyActive,
      fetchUser,
      setCompany,
      companies
    }),
    [
      company,
      login,
      fetchUser,
      isCompanyActive,
      isPractitioner,
      logout,
      user,
      setCompany,
      companies
    ]
  );

  if (fetchUserError) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <ErrorView />
      </Box>
    );
  }

  if (user && !company) {
    return <SelectCompany t={t} fetchCompany={fetchCompany} companies={companies} user={user} />;
  }

  return (
    <UserContext.Provider value={value}>{loading ? <AppLoading /> : children}</UserContext.Provider>
  );
}
