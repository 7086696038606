import {useCallback, useMemo, useState, useEffect} from 'react';
import {
  useBackendClient,
  Company,
  RefreshTokenError,
  ApiError,
  LocaleCode,
  CompanyId
} from '@legit.health/ui';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router';
import {COMPANY, ME} from '@/shared/const/backend';
import {practitionerSelectedKey} from '@/shared/const/storageKeys';
import AuthTokens from '@/shared/interfaces/AuthTokens';
import {CompanyTeaser} from '@/shared/interfaces/User';
import userLocaleLocalStorage from '@/shared/userLocale/userLocaleLocalStorage';
import queryClient from '@/shared/utils/reactQuery/queryClient';
import sentry from '@/shared/utils/sentry';
import {CurrentUserBase} from './types';

export default function useUser() {
  const backendClient = useBackendClient();
  const {i18n} = useTranslation();
  const [fetchUserError, setFetchUserError] = useState(false);
  const [credentialsHasExpired, setCredentialsHasExpired] = useState(false);
  const [user, setUser] = useState<CurrentUserBase | null>(null);
  const [company, setCompany] = useState<Company | null>(function () {
    const c = sessionStorage.getItem('company');
    return c ? JSON.parse(c) : null;
  });
  const [companies, setCompanies] = useState<CompanyTeaser[]>([]);

  const locale = user?.locale;
  const setUserAndSaveLocale = useCallback(
    function (newUser: CurrentUserBase | null) {
      sentry.setUser(newUser ? {id: newUser.id, email: newUser.email ?? undefined} : null);
      setUser(newUser);
      if (newUser && locale !== newUser.locale) {
        i18n.changeLanguage(newUser.locale);
        userLocaleLocalStorage.set(newUser.locale);
        // Clear query cache if the user has changed his language
        if (locale) {
          queryClient.getQueryCache().clear();
        }
      }
    },
    [i18n, locale]
  );

  const logout = useCallback(
    function () {
      backendClient.setAuthTokens(null);
      queryClient.getQueryCache().clear();
      setUserAndSaveLocale(null);
      setCompany(null);
      userLocaleLocalStorage.delete();
    },
    [setUserAndSaveLocale, backendClient]
  );

  const fetchCompany = useCallback(
    async function (companyId: CompanyId) {
      const companyResponse = await backendClient.sendRequest<Company>({
        method: 'GET',
        path: generatePath(COMPANY, {companyId})
      });
      setCompany(companyResponse.data);
      sessionStorage.setItem('company', JSON.stringify(companyResponse.data));
    },
    [backendClient]
  );

  const fetchUser = useCallback(
    async function () {
      try {
        const userResponse = await backendClient.sendRequest<
          CurrentUserBase & {
            companies: CompanyTeaser[];
          }
        >({
          method: 'GET',
          path: ME
        });
        const {data: userData} = userResponse;
        if (userData.companies[0] && userData.companies.length === 1) {
          await fetchCompany(userData.companies[0].id);
        } else {
          setCompanies(userData.companies);
        }
        const user = {...userData, locale: userData.locale.replace('_', '-') as LocaleCode};
        setUserAndSaveLocale(user);
        return true;
      } catch (error) {
        if (
          error instanceof RefreshTokenError ||
          (error instanceof ApiError && error.status === 401)
        ) {
          setCredentialsHasExpired(true);
          logout();
          return;
        }
        sentry.captureException(error);
        setFetchUserError(true);
      }
    },
    [logout, setUserAndSaveLocale, backendClient, fetchCompany]
  );

  const login = useCallback(
    async function (authTokens: AuthTokens) {
      backendClient.setAuthTokens(authTokens);
      sessionStorage.removeItem(practitionerSelectedKey);
      return (await fetchUser()) ?? false;
    },
    [fetchUser, backendClient]
  );

  useEffect(
    function () {
      if (backendClient.hasAuthTokens() && !user) {
        fetchUser();
      }
    },
    [fetchUser, user, backendClient]
  );

  const loading =
    Boolean(backendClient.hasAuthTokens()) && !(user && company) && !credentialsHasExpired;

  return useMemo(
    () => ({
      user,
      login,
      fetchUser,
      fetchUserError,
      logout,
      company,
      setCompany,
      loading,
      companies,
      fetchCompany
    }),
    [
      user,
      login,
      fetchUser,
      fetchUserError,
      logout,
      company,
      setCompany,
      loading,
      companies,
      fetchCompany
    ]
  );
}
