import {useEffect, useState} from 'react';
import {ONE_SECOND} from '@legit.health/ui';
import {Container, Grid2 as Grid, Box, Typography, Alert, AlertTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import robotBody from '@/shared/images/error-robot-body.svg';
import robotHead from '@/shared/images/error-robot-head.svg';
import isInternetExplorer from '@/shared/utils/isInternetExplorer';

export default function ErrorView() {
  const isIe = isInternetExplorer();
  const [isTimeoutFinished, setIsTimeoutFinished] = useState(false);
  const [secondsLeft, setScondsLeft] = useState(15);

  const {t} = useTranslation(['shared/common']);

  useEffect(function () {
    const id = setTimeout(function () {
      setIsTimeoutFinished(true);
    }, ONE_SECOND * 15);
    return () => clearTimeout(id);
  }, []);

  useEffect(
    function () {
      let id: null | ReturnType<typeof setTimeout> = null;
      if (secondsLeft > 0) {
        id = setTimeout(function () {
          setScondsLeft((s) => s - 1);
        }, ONE_SECOND);
      }
      return function () {
        if (id) {
          clearTimeout(id);
        }
      };
    },
    [secondsLeft]
  );

  return (
    <Container maxWidth="md" disableGutters>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6
          }}
        >
          {!isTimeoutFinished && (
            <Box
              sx={{
                mb: 4,
                pt: 4,
                minWidth: {
                  xs: '100%'
                }
              }}
            >
              <Alert severity="info">
                <AlertTitle>{t('shared/common:errorPage.newVersion.text')}</AlertTitle>
                <Typography>
                  {t('shared/common:errorPage.newVersion.progress', {secondsLeft})}
                </Typography>
              </Alert>
            </Box>
          )}
          {isTimeoutFinished && (
            <>
              <Box
                sx={{
                  mb: 2
                }}
              >
                <img src={robotHead} alt="Error message robot" />
                <Typography align="center" variant="h3">
                  {t('shared/common:errorPage.title')}
                </Typography>
                <Typography
                  align="center"
                  variant="h2"
                  sx={{
                    mb: '16px'
                  }}
                >
                  {t('shared/common:errorPage.subtitle')}
                </Typography>
                {isIe ? (
                  <Alert
                    severity="warning"
                    sx={{
                      mt: 2
                    }}
                  >
                    {t('shared/common:errorPage.ie')}
                  </Alert>
                ) : (
                  <Typography align="center">{t('shared/common:errorPage.text')}</Typography>
                )}
              </Box>
              <img src={robotBody} alt="Error message robot" />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
