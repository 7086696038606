export const FILE_MAX_SIZE = 8000000;
export const DATE_FORMAT = 'dd/MM/yyyy';

const backendApi = import.meta.env.VITE_BACKEND_API;

export const BACKEND_API = backendApi;

export const ME = '/users/me';
export const UPDATE_PROFILE = '/users/:id';
export const PRACTITIONER_ME = '/practitioner/me';
export const REGISTER = '/register';
export const RECOVER_PASSWORD = '/recover-password';
export const RESET_PASSWORD = '/reset-password';
export const LOGIN = '/login_check';
export const REFRESH_TOKEN = '/token/refresh';
export const CONFIRM_ACCOUNT = '/confirm-account';
export const CONTACT = '/contacts';
export const CONFIRMING_USER = '/confirming-user/:confirmationToken';

// company

export const COMPANY_REGISTER = '/companies/register';
export const COMPANY_GET_AVAILABLE_SUBSCRIPTION_PLANS =
  '/companies/:companyId/subscription-plans/available';
export const COMPANY_GET_SUBSCRIPTION_PLAN = '/companies/:companyId/subscription-plan';
export const COMPANY = '/companies/:companyId';
export const COMPANY_METRICS = '/companies/:companyId/company-metrics';
export const COMPANY_UPDATE_PAYMENT_METHOD =
  '/companies/:companyId/subscription-plan/update-payment-method';
export const COMPANY_CANCEL_SUBSCRIPTION_PLAN = '/companies/:companyId/subscription-plan/cancel';
export const GENERATE_LINK_FOR_CONNECTING_STRIPE_ACCOUNT =
  '/companies/:companyId/generate-link-for-connecting-stripe-account';
export const COMPANY_STRIPE_ACCOUNT = '/companies/:companyId/stripe-account';
export const COMPANY_FROM_PUBLIC_IDENTIFIER = '/public/companies/:identifier';

// practitioner roles
export const PRACTICIONER_ROLES = '/practitioner-roles';

// practitioner specialties
export const PRACTICIONER_SPECIALTIES = '/practitioner-specialties';

// practitioner

export const PRIVATE_INSURANCE_PROVIDER_LIST = '/private-insurance-providers';
export const PATIENTS = '/patients';
export const PATIENT = '/patients/:patientId';
export const DELETED_PATIENT = '/deleted-patients/:patientId';
export const PATIENT_RECOVER_PASSWORD = '/patients/:patientId/recover-password';
export const PATIENT_INTERACTIONS = '/patient-interactions';

// patient conditions
export const GET_SCORING_SYSTEM_HISTORY =
  '/patients/:patientId/patient-pathologies/:conditionId/history/scoringSystem/:scoringSystemId';
export const GET_SCORING_SYSTEMS_HISTORY = '/patients/:patientId/conditions/:conditionId/history';

export const PRACTICIONER_RECOVER_PASSWORD = '/practitioners/:practitionerId/recover-password';

// pathologies

export const BODY_SITES = '/body-sites';

// diagnosticReports

export const QUICK_DIAGNOSTIC_REPORTS = '/quick-diagnostic-reports';
export const DIAGNOSTIC_REPORTS = '/diagnostic-reports';
export const POST_FOLLOW_UP = '/diagnostic-reports';
export const DIAGNOSIS_SUPPORT = '/diagnosis-support';
export const PATIENT_DOCUMENTS = '/patients/:id/documents';
export const PATIENT_DOCUMENT = '/patients/:patientId/documents/:documentId';

export const DIAGNOSTIC_REPORT = '/diagnostic-reports/:diagnosticReportId';

// encounters

export const PATIENT_ENCOUNTERS = '/patients/:id/encounters';
export const CLARIFICATIONS = '/patients/:id/clarifications';
export const CLARIFICATION = '/patients/:patientId/clarifications/:clarificationId';
export const PATIENT_ENCOUNTER = '/patients/:patientId/encounters/:encounterId';
export const ENCOUNTER_CANCELLATION_REASONS = '/encounter-cancellation-reasons';
export const REQUEST_ENCOUNTER = '/patients/:patientId/encounters';

// scoring systems

export const SCORING_SYSTEMS = '/scoring-systems';
export const SCORING_SYSTEM = '/scoring-systems/:id';
// events

export const EVENT_TYPES_LIST = '/event-types';
export const EVENT_LIST = '/users/:userId/events';
export const EVENT = '/users/:userId/events/:eventId';
export const EVENT_EDIT = '/practitioners/:practitionerId/events/:eventId';
export const EVENT_CREATE = '/practitioners/:practitionerId/events';
export const EVENT_DELETE = '/practitioners/:practitionerId/events/:eventId';

// notifications

export const NOTIFICATIONS_LIST = '/users/:userId/notifications';
export const MARK_NOTIFICATION_AS_READ =
  '/users/:userId/notifications/:notificationId/mark-as-read';

// subscription

export const GET_USER_BILLING_INVOICES = '/users/:userId/billing-invoices';
export const CANCEL_USER_SUBSCRIPTION_PLAN = '/users/:userId/subscription-plan/cancel';
export const UPDATE_USER_SUBSCRIPTION_PLAN = '/users/:userId/subscription-plan';
export const UPDATE_PAYMENT_METHOD = '/users/:userId/subscription-plan/update-payment-method';

// User action Records
export const USER_ACTION_RECORDS = '/users/user-action-records';
export const GET_USER_ACTION_RECORDS = '/users/:id/user-action-records';

// Patient payment order
export const PATIENT_PAYMENT_ORDERS = '/patient-payment-orders';
export const PATIENT_PAYMENT_ORDER = '/patient-payment-orders/:id';

// Commands
export const COMMAND = '/commands/:commandId';

// Medication requests
export const MEDICATION_REQUESTS = '/medication-requests';
export const MEDICATION_REQUEST = '/medication-requests/:medicationRequestId';
