import {useState} from 'react';
import {BaseUser, CompanyId, mapArrayToSelectOptions, SelectField} from '@legit.health/ui';
import {Box, Button, Paper, Typography} from '@mui/material';
import {TFunction} from 'i18next';
import logo from '@/shared/images/legit_health_logo.svg';
import {CompanyTeaser} from '@/shared/interfaces/User';

export default function SelectCompany({
  companies,
  fetchCompany,
  t,
  user
}: {
  companies: CompanyTeaser[];
  fetchCompany: (companyId: CompanyId) => Promise<void>;
  t: TFunction;
  user: BaseUser;
}) {
  const [companyId, setCompanyId] = useState<CompanyId>('');

  function handleSelectCompany() {
    fetchCompany(companyId);
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'grey.100'
      }}
    >
      <Paper
        elevation={1}
        sx={{
          px: 2,
          py: 4,
          minWidth: {
            xs: '90%',
            lg: '500px'
          }
        }}
      >
        <Box
          sx={{
            mb: 4,
            '& > img': {
              display: 'block',
              width: {
                xs: '100%',
                sm: '200px'
              },
              height: 'auto',
              mx: 'auto'
            }
          }}
        >
          <img src={logo} alt="Legit.Health" />
        </Box>
        <Typography mb={2}>
          {t('shared/common:name')}: {user.fullname}
        </Typography>
        <Typography variant="h4" sx={{mb: 4}}>
          {t('shared/common:selectCompany.title')}
        </Typography>
        <SelectField
          value={companyId}
          onChange={(event) => setCompanyId(event.target.value)}
          label={t('shared/common:select')}
          name="company"
          id="company"
          options={mapArrayToSelectOptions(companies, 'name', 'id')}
          required
        />
        <Button
          disableElevation
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSelectCompany}
          sx={{
            mt: 2
          }}
          data-testid="selectCompanyBtn"
        >
          {t('shared/common:continue')}
        </Button>
      </Paper>
    </Box>
  );
}
